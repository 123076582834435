import './App.scss'

import Navbar from './components/Navbar.js'
import LandingPage from './components/landing/LandingPage.js'
import Dakwerken from './components/Diensten/Dakwerken.js'
import Gevelwerken from './components/Diensten/Gevelwerken.js'
import Binnenwerken from './components/Diensten/Binnenwerken.js'
import Referentiewerken from './components/Referentiewerken.js'
import Footer from './components/landing/LandingSectionE'
import Contact from './components/Contact.js'
import SimpleReactLightbox from "simple-react-lightbox";

import { HashRouter as Router, Route } from 'react-router-dom'



function App() {
  return (
    <div className="App">
      <Router>
        <Navbar></Navbar>
        <Route exact path="/" component={LandingPage}></Route>
        <Route exact path="/dakwerken" component={Dakwerken}></Route>
        <Route exact path="/gevelwerken" component={Gevelwerken}></Route>
        <Route exact path="/binnenwerken" component={Binnenwerken}></Route>
        <SimpleReactLightbox>
          <Route exact path="/referentiewerken" component={Referentiewerken}></Route>
        </SimpleReactLightbox>
        <Route exact path="/contact" component={Contact}></Route>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
