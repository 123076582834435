import React from 'react'
import { SRLWrapper } from 'simple-react-lightbox'

export default function Referentiewerken() {
  return (
    <div className="referentiewerken">
      <div class="container diensten-header">
        <h1>Referentiewerken / Transformaties</h1>
        <p>
          Bekijk hier een aantal van onze realisaties
        </p>
      </div>
      <div className="container">
        <SRLWrapper>
          <div className="image-gallery">
            <img alt="" src="/img/referentiewerken/gevel.png" class="img-1">
            </img>

            <img alt="" src="/img/referentiewerken/osb.png" class="img-2">
            </img>

            <img alt="" src="/img/referentiewerken/velux.png" class="img-3">
            </img>

            <img alt="" src="/img/referentiewerken/gevel2.png" class="img-4">
            </img>

            <img alt="" src="/img/referentiewerken/image1.jpg" class="img-5">
            </img>

            <img alt="" src="/img/referentiewerken/hellenddak1.png" class="img-6">
            </img>

            <img alt="" src="/img/referentiewerken/tegels.png" class="img-7">
            </img>

            <img alt="" src="/img/referentiewerken/parket.png" class="img-8">
            </img>

            <img alt="" src="/img/referentiewerken/GRS.jpg" class="img-9">
            </img>

            <img alt="" src="/img/referentiewerken/dakgoot.png" class="img-10">
            </img>

            <img alt="" src="/img/referentiewerken/GRS2.jpg" class="img-11">
            </img>

            <img alt="" src="/img/referentiewerken/chape1.png" class="img-12">
            </img>

            <img alt="" src="/img/referentiewerken/gevel4.png" class="img-13">
            </img>
          </div>
        </SRLWrapper>
      </div>
    </div>
  )
}
