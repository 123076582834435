import React from 'react'

export default function LandingSectionB() {
  return (
    <section className="section-b">
      <div className="container">
        <div className="text-container">
          <h1>Verbouwen in vertrouwen</h1>
          <p>
            GRS-projects is een dynamische onderneming in renovatiewerken.
          </p>
          <p>
            Voor elk specifiek werk zullen wij het gepaste team aanbieden met hun eigen specialiteit.
            Onze gespecialiseerde vakmannen delen allemaal dezelfde waarden en geven het beste van zichzelf voor u een optimale service
            en kwaliteit te garanderen.
          </p>
          <p>
            Om deze service te blijven garanderen zijn wij erop gebrand op de hoogte te blijven van alle nieuwe materialen
            en technieken. Jaarlijks worden er dan ook meerdere opleidingen gevolgd.
          </p>
          <p>
            Elke opdracht wordt door ons dan ook met dezelfde dosis passie en vakmanschap uitgevoerd!
          </p>
        </div>
        <img alt="" className="section-b-image" src="img/image2.jpg"></img>
      </div>
    </section >
  )
}
