import React from 'react'


export default function Binnenwerken() {
  return (
    <div>
      <div class="container diensten-header">
        <h1>binnenwerken van a tot z</h1>
        <p>
          Wilt u een nieuwe look creëren voor uw interieur?
        </p>
        <button>Ontdek onze diensten</button>
      </div>
      <div class="cardswrapper">
        <div className="cards">
          <div className="card">
            <div className="card-container">
              <img alt="" src="img/binnenwerken/chapewerken1.jpg"></img>
            </div>
            <div className="details">
              <h3>Funderings- en chapewerken</h3>
              <p>
                Funderings- en chapewerken zijn de basis van alles!Funderings- en chapewerken zijn de basis van alles!
              </p>
              <p>
                Kies dan ook voor een stevige ondergrond om op te bouwen.
                Dit zal niet enkel bepalen of jouw vloer er mooi zal blijven uitzien,
                maar geeft ook de stevigheid aan kasten of keuken die er zullen opkomen.
              </p>
              <p>
                Bovendien kan u nog kiezen om te polieren als eindafwerking.
              </p>
              <p>
                Bij GRS Projects werken wij steeds met kwaliteit zodat u steeds verzekerd bent van een topproduct. Daarnaast adviseren wij u over droogtijden, vezels, dikte en veel meer.
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/binnenwerken/betegelen1.jpg"></img>
            </div>
            <div className="details">
              <h3>Betegelen</h3>
              <p>Voor al uw tegelwerken bent u bij GRS Projects aan het juiste adres!
              </p>
              <p>
                Wij garanderen dat uw vloer- en tegelwerken tot in de puntjes verzorgd zal worden.
              </p>
              <p>
                Tegels zijn ideaal om gebruiksruimtes als keuken of badkamer mee te bevloeren. Ze zijn vochtbestendig en erg makkelijk schoon te houden.
              </p>
              <p>
                Laat u begeleiden in het ruim assortiment van natuursteen, keramische tegels, mozaïeksteentjes of exclusieve tegels.
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/binnenwerken/parket1.jpg"></img>
            </div>
            <div className="details">
              <h3>Laminaat, vynil en parket</h3>
              <p>
                Voor een egale plaatsing en correcte afwerking kiest u best voor een erkende vakman.
              </p>
              <p>
                Met een persoonlijke service begeleiden wij u bij de keuze tussen laminaat, vynil en parket.
                Wij werken samen met verschillende partners waar u kan kiezen uit een breed assortiment aan modellen.
              </p>
              <p>
                Uiteraard is de klant vrij om nog op andere adressen hun vloerbekleding aan te kopen, maar ook dan blijft GRS Projects de ideale partner voor het plaatsen van uw vloerbekleding.
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/binnenwerken/gevelpanelen1.jpg"></img>
            </div>
            <div className="details">
              <h3>Schilderwerken</h3>
              <p>
                Bij GRS Projects gebruiken wij steeds de beste kwaliteit verf voor een perfect resultaat.
              </p>
              <p>
                Laat onze adviseur u bijstaan bij het kiezen van de perfecte kleur!
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/binnenwerken/gipsplaten1.jpg"></img>
            </div>
            <div className="details">
              <h3>Gipsplaten</h3>
              <p>
                Kies voor elke klus de juiste plaat. Voor de verschillende ruimtes in uw woning is een andere plaat of dikte vereist. Een klassieke A-plaat heeft 2 afgeschuurde boorden en is verkrijgbaar in verschillende afmetingen en diktes. WR-platen, die gebruikt worden voor vochtige ruimtes, hebben een verhoogde waterwerendheid en brandwerendheid. RF-platen hebben nog een verhoogde weerstand tegen brand.
                Na het bepleisteren van gipsplaten zijn deze schilderklaar.
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/binnenwerken/loodgieterij.jpg"></img>
            </div>
            <div className="details">
              <h3>Elektriciteit & Loodgieterij</h3>
              <p>
                Onze elektriciens richten zich op elektrische installaties, zoals de meterkast, stopcontacten en verlichting.
                Kies zeker voor een gecertificeerd elektricien van GRS Projects, omdat deze werkzaamheden risicovol zijn.
                Onze elektriciens beschikken over de juiste kennis, ervaring en gereedschappen voor alle elektriciteitswerken succesvol af te ronden.
              </p>
              <p>
                Voor het uitvoeren van loodgieterwerkzaamheden (sanitair, chauffages, …) bent u bij ons aan het juiste adres.
                Onze loodgieters zorgen voor installatie, onderhoud en reparatie. Wanneer u de eerste twee onderdelen op de juiste wijze en met regelmaat laat uitvoeren, zal de derde categorie in veel gevallen niet noodzakelijk zijn. Door het aanbieden van deze totaaloplossing heeft u in ons een partner voor lange termijn!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
