import React from 'react'
import { useMediaQuery } from 'react-responsive'
import ContactForm from './ContactForm'

export default function Contact() {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return (
    <div>
      <section className="section-contact-page" style={{ backgroundImage: isTabletOrMobile ? 'url(/img/contact-mobile.jpg)' : 'url(/img/contact.jpg)' }}>
        <div class="contact-wrapper">
          <div className="container">
            <h1>Contact Opnemen</h1>
            <p>Hebt u vragen over onze diensten? Neem dan snel contact op! Wij komen binnen de 48
            uur bij u ter plaatse om een vrijblijvende prijsofferte op te maken.
            Bovendien geven wij u gericht advies over de materialen waar we mee werken.
          </p>
          </div>
          <div className="container-contact">
            <div className="contactInfo">
              <div className="box">
                <div className="icon">
                  <i className="fa fa-home"></i>
                </div>
                <div className="text">
                  <h3>Adres</h3>
                  <p>Pantserschipstraat 129 </p>
                  <p>9000 Gent</p>
                </div>
              </div>
              <div className="box">
                <div className="icon">
                  <i className="fa fa-envelope"></i>
                </div>
                <div className="text">
                  <h3>Email</h3>
                  <p>renovatie@grs-projects.be</p>
                </div>
              </div>
              <div className="box">
                <div className="icon">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="text">
                  <h3>Tel.</h3>
                  <p>+32 497 294 941</p>
                </div>
              </div>
            </div>

            <ContactForm></ContactForm>
          </div>
        </div>
      </section >
    </div>
  )
}
