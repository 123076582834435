import React, { Component } from 'react'
import axios from 'axios'

export class ContactForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      naam: '',
      email: '',
      tel: '',
      bericht: ''
    };
    // This binding is necessary to make `this` work in the callback
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log(this.state)
    axios.post('http://grs-projects.be/contactform.php', this.state)
      .then((response) => {
        this.resetForm()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  resetForm() {
    this.setState({ naam: "", email: "", tel: "", bericht: "" })
  }

  onNameChange(event) {
    this.setState({ naam: event.target.value })
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  onTelChange(event) {
    this.setState({ tel: event.target.value })
  }

  onMessageChange(event) {
    this.setState({ bericht: event.target.value })
  }


  render() {
    return (
      <div className="contactForm">
        <form onSubmit={this.handleSubmit.bind(this)}>
          <h2>Verzend boodschap</h2>
          <div className="inputBox">
            <input type="text" value={this.state.naam} onChange={this.onNameChange.bind(this)} required placeholder="Naam"></input>
          </div>
          <div className="inputBox">
            <input type="email" value={this.state.email} onChange={this.onEmailChange.bind(this)} required placeholder="Email"></input>
          </div>
          <div className="inputBox">
            <input type="text" value={this.state.tel} onChange={this.onTelChange.bind(this)} required placeholder="Tel. nummer"></input>
          </div>
          <div className="inputBox">
            <textarea type="text" value={this.state.bericht} onChange={this.onMessageChange.bind(this)} required placeholder="Bericht"></textarea>
          </div>
          <div className="inputBox">
            <button>Verzend</button>
          </div>
        </form>
      </div>
    )
  }
}

export default ContactForm
