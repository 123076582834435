import React from 'react'
import { NavLink } from 'react-router-dom'

export default function LandingSectionA() {
  return (
    <section className="section-a">
      <div className="container">
        <img alt="" src="img/landingpicture.jpg"></img>
        <div className="landing-text">
          <h1>GRS Projects, uw ideale partner voor alle renovatiewerken.</h1>
          <p>Bent u van plan renovatiewerken uit te voeren? Kies dan voor de ervaring en knowhow van GRS Projects!</p>
          <p>Wij zijn gespecialiseerd in totaalrenovaties.</p>
          <ul>
            <li>
              <p>
                <NavLink to="/dakwerken">
                  <i style={{ 'color': '#ffc800' }} className="fa fa-chevron-circle-right">
                  </i>&nbsp;Dakwerken
                </NavLink>
              </p>
            </li>
            <li>
              <p>
                <NavLink to="/gevelwerken">
                  <i style={{ 'color': '#ffc800' }} className="fa fa-chevron-circle-right">
                  </i>&nbsp;Gevelwerken
                </NavLink>
              </p>
            </li>
            <li>
              <p>
                <NavLink to="/binnenwerken">
                  <i style={{ 'color': '#ffc800' }} className="fa fa-chevron-circle-right">
                  </i>&nbsp;Binnenwerken
                </NavLink>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section >
  )
}
