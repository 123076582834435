import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import './Navbar.scss';

function Navbar() {

  const [active, setActive] = useState(0);

  return (
    <div>
      <header>
        <div className="container">
          <nav className={active === 1 ? 'active' : ''}>
            <div className="menu-icons">
              {active === 0 && <a onClick={() => setActive(1)}><ion-icon name="menu" className="menu-icon"></ion-icon></a>}
              {active === 1 && <a onClick={() => setActive(0)}><ion-icon name="close" className="close-icon"></ion-icon></a>}
            </div>
            <a href="index.html" className="logo">
              <img href="" src="img/logo.jpg"></img>
            </a>
            <ul className="nav-list">
              <li>
                <NavLink to="/" onClick={() => setActive(0)} exact activeClassName="active-route">Home</NavLink>
              </li>
              <li>
                <a href="#">
                  Diensten &nbsp;
                  <i className="fa fa-caret-down"></i>
                </a>
                <ul className="sub-menu">
                  <li>
                    <NavLink to="/dakwerken" onClick={() => setActive(0)} exact activeClassName="active-route">Dakwerken</NavLink>
                  </li>
                  <li>
                    <NavLink to="/gevelwerken" onClick={() => setActive(0)} exact activeClassName="active-route">Gevelwerken</NavLink>
                  </li>
                  <li>
                    <NavLink to="/binnenwerken" onClick={() => setActive(0)} exact activeClassName="active-route">Binnenwerken</NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to="/referentiewerken" onClick={() => setActive(0)} exact activeClassName="active-route">Referentiewerken</NavLink>
              </li>
              <li>
                <NavLink to="/contact" onClick={() => setActive(0)} exact activeClassName="active-route">Contact</NavLink>
              </li>

            </ul>
          </nav>
        </div>
      </header >
    </div >
  )
}

export default Navbar