import React from 'react'
import ContactForm from '../ContactForm'

export default function LandingSectionD() {
  return (
    <section className="section-contact">
      <div className="container">
        <h1>Contact Opnemen</h1>
        <p>Hebt u vragen over onze diensten? Neem dan snel contact op! Wij komen binnen de 48
        uur bij u ter plaatse om een vrijblijvende prijsofferte op te maken.
        Bovendien geven wij u gericht advies over de materialen waar we mee werken.
        </p>
      </div>
      <div className="container-contact">
        <div className="contactInfo">
          <div className="box">
            <div className="icon">
              <i className="fa fa-home"></i>
            </div>
            <div className="text">
              <h3>Adres</h3>
              <p>Pantserschipstraat 129 <br></br>9000 Gent</p>
            </div>
          </div>
          <div className="box">
            <div className="icon">
              <i className="fa fa-envelope"></i>
            </div>
            <div className="text">
              <h3>Email</h3>
              <p>renovatie@grs-projects.be</p>
            </div>
          </div>
          <div className="box">
            <div className="icon">
              <i className="fa fa-phone"></i>
            </div>
            <div className="text">
              <h3>Tel.</h3>
              <p>+32 497 294 941</p>
            </div>
          </div>
        </div>

        <ContactForm></ContactForm>
      </div>
    </section >
  )
}
