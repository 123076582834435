import React from 'react'

import LandingSectionA from './LandingSectionA.js'
import LandingSectionB from './LandingSectionB.js'
import LandingSectionC from './LandingSectionC.js'
import LandingSectionD from './LandingSectionD.js'


export default function LandingPage() {
  return (
    <div>
      <LandingSectionA></LandingSectionA>
      <LandingSectionB></LandingSectionB>
      <LandingSectionC></LandingSectionC>
      <LandingSectionD></LandingSectionD>
    </div>
  )
}
