import React from 'react'

export default function LandingSectionC() {
  return (
    <section className="section-c">
      <div className="container">
        <div className="text-container">
          <h1>Vrijblijvende prijsofferte</h1>
          <p>
            GRS Projects heeft het vertrouwen van zowel particulieren als bedrijven. U vindt hen elke dag op een werf in de ruime regio rond Gent.
          </p>
          <p>
            Open communicatie is voor ons heel belangrijk! Zo trachten wij telkens een <b>correcte en vaste prijs</b> aan te bieden met een gedetailleerde prijsofferte.
          </p>
          <p>
            We staan garant voor <b>eerlijkheid, verzorgd werk,</b> en <b>stipte planning</b>. En dit alles aan <b>correcte prijzen.</b>
          </p>
        </div>
      </div>
    </section >
  )
}
