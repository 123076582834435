import React from 'react'


export default function Gevelwerken() {
  return (
    <div>
      <div class="container diensten-header">
        <h1>Gevelrenovaties tot in de puntjes</h1>
        <p>
          Bent u van plan uw gevel(s) onder handen te nemen?
        </p>
        <button>Ontdek onze diensten</button>
      </div>
      <div class="cardswrapper">
        <div className="cards">
          <div className="card">
            <div className="card-container">
              <img alt="" src="img/gevelwerken/pleisterwerken1.jpg"></img>
            </div>
            <div className="details">
              <h3>Pleisterwerken</h3>
              <p>
                Bepleisteren van gevels is populairder dan ooit!
              </p>
              <p>Door het aanbrengen van een pleisterlaag en pleisternetten zullen uw gevels gevoelig verstevigen en een nieuw leven krijgen.
              </p>
              <p>
                GRS Projects is al jaren gespecialiseerd in al uw pleisterwerken!
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/gevelwerken/metselwerken1.jpg"></img>
            </div>
            <div className="details">
              <h3>Metselwerken</h3>
              <p>Voor metselwerken groot en klein kan je steeds bij GRS Projects terecht!
              </p>
              <p>
                Onze metsers hebben reeds jaren ervaring en gaan met de juiste precisie tewerk voor een perfecte afwerking.
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/gevelwerken/steenstrips1.jpg"></img>
            </div>
            <div className="details">
              <h3>Steenstrips</h3>
              <p>
                Indien u de plaats niet heeft om te metsen kies dan voor steenstrips! Steenstrips zijn plaatsbesparend en ideaal om uw gevel in een nieuw jasje te steken. Daarnaast heeft u hierdoor meer plaats om te isoleren.
                Na de afwerking is de gevel niet meer te onderscheiden met een traditionele bakstenen muur.
              </p>
              <p>
                GRS Projects staat u graag bij met de beste <b>vakmannen!</b>
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/gevelwerken/gevelpanelen1.jpg"></img>
            </div>
            <div className="details">
              <h3>Gevelpanelen</h3>
              <p>
                Gevelpanelen heb je in verschillende materialen.
                Zo kan je kiezen voor gevelpanelen in hout, PVC, aluminium, Trespa of Rockpanel. Elk met hun eigen pluspunten en unieke uitstraling.
              </p>
              <p>
                GRS Projects helpt je graag de juiste keuze maken door <b>advies op maat!</b>
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/gevelwerken/leien1.jpg"></img>
            </div>
            <div className="details">
              <h3>Leien</h3>
              <p>
                Leien zijn vooral gekend van op de daken, maar tegenwoordig zijn ze ook een populair bekledingsmateriaal voor gevels.
              Net zoals bij dakleien zijn de troeven voor gevelleien dat ze een <b>lange levensduur</b> hebben en <b>weinig onderhoud</b> vragen.
              </p>
              <p>
                Tegenwoordig zijn leien te vinden in trendy kleuren en verschillende afmetingen.
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/gevelwerken/schilderen1.jpg"></img>
            </div>
            <div className="details">
              <h3>Schilderen</h3>
              <p>
                Bij GRS Projects gebruiken wij steeds de beste kwaliteit verf voor een perfect resultaat.
              </p>
              <p>
                Laat onze adviseur u bijstaan bij het kiezen van de perfecte kleur!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
