import React from 'react'

export default function LandingSectionE() {
  return (
    <section className="section-e">
      <div className="container">
        <div class="footer-logo-container">
          <div className="logo">
            <img alt="" src="img/logo.jpg"></img>
            <div className="text">
              <p>GRS Projects BV</p>
              <p>Pantserschipstraat 129</p>
              <p>9000 Gent</p>
            </div>
          </div>
        </div>
        <div class="footer-contact-container">
          <div className="text">
            <p>
              <span>
                <i className="fa fa-phone"></i><b> Stijn</b> zaakvoerder/adviseur: +32 497 294 941
            </span>
            </p>
            <p>
              <span>
                <i className="fa fa-phone"></i><b> Genario</b> zaakvoerder/werfleider: +32 483 601 435
            </span>
            </p>
            <p>
              <span>
                <i className="fa fa-envelope"></i> <b>renovatie@grs-projects.be</b>
              </span>
            </p>
            <p>
              <span>
                <i className="fa fa-desktop"></i> Website by <a href="http://michaeltimmerman.com" target="_blank">Michaël Timmerman</a>
              </span>
            </p>
          </div>
        </div>
      </div>

    </section >
  )
}
