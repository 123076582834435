import React from 'react'

export default function Dakwerken() {
  return (
    <div>
      <div class="container diensten-header">
        <h1>Perfecte dakwerken aan correcte prijzen</h1>
        <p>Denkt u erover na om uw dak te vernieuwen? </p>
        <p>Neem dan zeker contact met ons op!</p>
        <p>Voor alle soorten daken kan u steeds bij GRS projects terecht.</p>
        <button>Ontdek onze diensten</button>
      </div>
      <div class="cardswrapper">
        <div className="cards">

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/dakwerken/image3.jpg"></img>
            </div>
            <div className="details">
              <h3>Hellende daken</h3>
              <p>
                Voor hellende daken hebt u de keuze uit meerdere types en kleuren van dakpannen.
                Wij bieden een ruime keuze aan dakbedekkingen voor zowel pannen als leien.
                
              </p>
              <p>Daarnaast zorgen we ook voor dakramen, dakgoten en een prima isolatie conform de meest recente norm.
                Als u bij GRS Projects een nieuw dak laat leggen, mag u gerust zijn van de <b>beste kwaliteit</b>,
                zodat u er vele jaren op kan vertrouwen.
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/dakwerken/platdak1.jpg"></img>
            </div>
            <div className="details">
              <h3>Platte daken</h3>
              <p>Voor platte daken zijn er verschillende soorten dakbedekking beschikbaar.
                Wij bieden de keuze tussen de twee populairsten <b>roofing</b> en <b>EPDM</b>.
              </p>
              <p>
                Verder kunnen we ook nog koepels, lichtstraten en isolatie aanbieden.
                Bij GRS Projects bent u zeker van de beste kwaliteit en een vlotte uitvoering.
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/dakwerken/isolatiedak1.jpg"></img>
            </div>
            <div className="details">
              <h3>Isoleren van daken</h3>
              <p>
                Denk er zeker aan om voldoende te isoleren! Vanaf 2020 moeten de daken van alle huur- en koopwoningen die voor 1 januari 2006 aangesloten zijn op het elektriciteitsnet voorzien zijn van isolatie.
              </p>
              <p>
                Bovendien zullen uw stookkosten ontzettend verlagen.
                De adviseurs van GRS Projects zullen u graag met raad bijstaan in verband met eventuele premies.
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/dakwerken/dakgoot1.jpg"></img>
            </div>
            <div className="details">
              <h3>Dakgoten</h3>
              <p>
                Naast het renoveren van uw dak is het belangrijk dat uw water goed afloopt door middel van de dakgoten.
                Deze heb je ook in verschillende vormen. Er zijn hanggoten in zink, koper, PVC of aluminium.
                Daarnaast kan je een bakgoot uittimmeren die je kan bekleden.
                De waterdichting van de bakgoot kan in zink, koper, EPDM of roofing geplaatst worden.
              </p>
              <p>
                GRS Projects is al jaren vertrouwd met al deze materialen, met als gevolg een <b>snelle service</b> en de <b>beste kwaliteit</b>!
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/dakwerken/lichtkoepel1.jpg"></img>
            </div>
            <div className="details">
              <h3>Wentelramen-lichtkoepels</h3>
              <p>
                Breng het <b>licht</b> naar binnen en zorg voor een <b>perfecte ventilatie</b> door het plaatsen van dakvensters en/of platdakvensters!
              </p>
              <p>
                Bij GRS Projects zijn wij gespecialiseerd in het plaatsen van verschillende types dak- en platdakvensters.
                Zo heeft de klant de keuze uit een breed assortiment van wentelramen, lichtkoepels, modulaire lichtstraten en lichttunnels.
              </p>
            </div>
          </div>

          <div className="card">
            <div className="card-container">
              <img alt="" src="img/dakwerken/reiniging1.jpg"></img>
            </div>
            <div className="details">
              <h3>Reiniging</h3>
              <p>
                Denk er zeker over na om uw dak regelmatig eens te laten reinigen.
              </p>
              <p>
                Dit zorgt niet alleen voor een nieuwe look, maar het zal ook de levensduur van het dak uitermate verlengen.
              </p>
              <p>
                Daarnaast zijn er reeds tal van behandelingen mogelijk voor het beschermen van uw dak. Laat u hier dan ook degelijk in begeleiden.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
